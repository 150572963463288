<template>
  <div class="ative-list-container">
    <div class="ct-container">
      <div class="filter-container">
        <div class="brand-filter" :class="brandToggle">
          <span>商家选择:</span>
          <Dropdown style="margin-left: 20px" @on-click="onBandItemClick">
            <Button type="primary">
              <span>{{ brandName }}</span>
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem :name="-1" :selected="brandType == -1">
                全部</DropdownItem
              >
              <DropdownItem
                :name="item.id"
                :selected="brandType == item.id"
                v-for="(item, index) in brandList"
                :key="index"
                >{{ item.name }}</DropdownItem
              >
            </DropdownMenu>
          </Dropdown>
        </div>
        <div class="type-filter">
          <Dropdown style="margin-left: 20px" @on-click="onTypeItemClick">
            <Button type="primary">
              <span>{{ typeName }}</span>
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem
                :name="item.type"
                :selected="type == item.type"
                v-for="item in typeList"
                :key="item.type"
                >{{ item.name }}</DropdownItem
              >
            </DropdownMenu>
          </Dropdown>
        </div>
        <div class="month-filter">
          <DatePicker
            type="daterange"
            placement="bottom-end"
            placeholder="请选择日期范围,默认全部"
            style="width: 200px"
            @on-change="handleDateChange"
          ></DatePicker>
        </div>
        <div class="price-container">
          <span>总续费金额:</span><span class="price"> {{ totalPrice }}元</span>
        </div>
      </div>
      <Table
        :columns="userColumns"
        :data="userData"
        border
        :max-height="tableMaxHeight"
      >
        <template slot-scope="{ row, index }" slot="action">
          <Button
            type="primary"
            size="small"
            style="margin-right: 5px"
            @click="showUserActiveInfo(index)"
            >查看</Button
          >
        </template>
      </Table>
      <div style="margin: 20px 12px">
        <div style="display: flex; flex-direction: row-reverse">
          <Page
            :total="totalCount"
            :current="currentPage"
            @on-change="changePage"
            :page-size="pageSize"
            show-total
          ></Page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinDevice } from "@/util/mixin";

import Store from "@/store";

import api from "@/api";

export default {
  mixins: [mixinDevice],
  data() {
    let columnWidth = Store.state.deviceType == "mobile" ? 140 : "auto";
    return {
      brandList: [],
      typeList: [
        { type: 0, name: "机器激活" },
        { type: 1, name: "vip续费" },
      ],
      type: 0,
      currentPage: 1,
      totalCount: 0,
      pageSize: 50,
      brandType: -1,
      userColumns: [
        {
          title: "用户名",
          key: "username",
          width: columnWidth,
        },
        {
          title: "商家名称",
          key: "accountName",
          width: columnWidth,
        },
        {
          title: "续费日期",
          key: "active_time",
          width: columnWidth,
        },
        {
          title: "续费金额",
          key: "priceInfo",
          width: columnWidth,
        },
        {
          title: "注册日期",
          key: "register_time",
          width: columnWidth,
        },
        {
          title: "到期日期",
          key: "active_expired_time",
          width: columnWidth,
        },
        {
          title: "操作",
          slot: "action",
          width: columnWidth,
          align: "center",
        },
      ],
      userData: [],
      brandToggle: "brandHidden",
      startTime: null,
      endTime: null,
      wePrice: 0,
      applePrice: 0,
      totalPrice: 0,
    };
  },
  computed: {
    typeName() {
      return this.typeList.filter((item) => {
        return item.type == this.type;
      })[0].name;
    },
    tableMaxHeight() {
      return this.isMobile() ? 540 : 700;
    },
    brandName() {
      let user = this.store.state.user;
      this.$log.debug(user);
      if (user.user_type != 0) {
        return user.merchant_name || "";
      }
      if (this.brandType == -1) return "全部";
      return this.brandList.filter((item) => {
        return item.id == this.brandType;
      })[0].name;
    },
  },
  methods: {
    showUserActiveInfo(index) {
      let user = this.userData[index];
      console.log(user);
      this.$router.push({
        name: "user-active-records",
        query: { id: user.user_id, name: user.username },
      });
    },
    handleDateChange(val) {
      this.$log.debug(val);
      this.startTime = val[0];
      this.endTime = val[1];
      this.currentPage = 1;
      this.loadData();
    },
    onBandItemClick(name) {
      this.$log.debug("name: ", name);
      if (name != this.brandType) {
        this.brandType = name;
        this.currentPage = 1;
        this.loadData();
      }
    },
    onTypeItemClick(name) {
      this.$log.debug("type: ", name);
      if (name != this.type) {
        this.type = name;
        this.currentPage = 1;
        this.loadData();
      }
    },
    changePage(newPage) {
      this.currentPage = newPage;
      this.$log.debug("currentPage: ", this.currentPage);
      this.loadData();
    },
    loadMerchants() {
      api
        .merchantList()
        .then((res) => {
          this.brandList = res.merchants;
          this.loadData();
        })
        .catch((err) => {
          this.$Message.error("拉取商家数据失败: " + err.message);
        });
    },
    loadData() {
      var stime, etime;
      if (this.startTime) {
        stime = new Date(this.startTime).getTime() / 1000;
      }
      if (this.endTime) {
        etime = new Date(this.endTime).getTime() / 1000;
      }
      this.$log.debug(stime, "----", etime);
      let btype;
      let user = this.store.state.user;
      if (user.user_type == 0) {
        btype = this.brandType;
      }
      api
        .userActivedList(
          btype,
          this.currentPage,
          this.pageSize,
          this.type,
          stime,
          etime
        )
        .then((res) => {
          this.$log.debug(res);
          if (res.active_records && res.active_records.length > 0) {
            var branchName = this.brandName;
            this.userData = res.active_records.map((item) => {
              var newItem = item;
              if (this.type == 0) {
                newItem.priceInfo = "0元(首次激活)";
              } else {
                newItem.priceInfo =
                  "" + item.price / 100 + "元(" + item.pay_type + ")";
              }
              newItem.accountName = branchName;
              return newItem;
            });
            if (this.type == 1) {
              this.totalPrice = res.total_price / 100;
              this.wePrice = res.we_price / 100;
              this.applePrice = res.apple_price / 100;
            } else {
              this.totalPrice = 0;
              this.wePrice = 0;
              this.applePrice = 0;
            }
            this.totalCount = res.total_count;
          } else {
            this.userData = [];
            this.totalCount = 0;
            this.totalPrice = 0;
            this.wePrice = 0;
            this.applePrice = 0;
          }
        })
        .catch((err) => {
          this.$log.debug(err);
        });
    },
  },
  mounted() {
    let user = this.store.state.user;
    if (user.user_type == 0) {
      this.brandToggle = "brandShow";
      this.loadMerchants();
    } else {
      this.loadData();
    }
  },
};
</script>

<style lang="less" scoped>
.ative-list-container {
  .filter-container {
    margin-bottom: 12px;
    padding: 12px;
    border: 1px dashed green;
    display: flex;

    .brandHidden {
      display: none;
    }

    .brandShow {
      display: block;
    }
    .month-filter {
      margin-left: 12px;
    }

    .price-container {
      margin-left: 12px;
      display: flex;
      align-items: center;
      font-size: 16px;

      .price {
        color: red;
        margin-left: 4px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 750px) {
      flex-direction: row;
      flex-wrap: wrap;
      .type-filter,
      .month-filter,
      .price-container {
        margin-bottom: 8px;
      }
    }
  }
}
</style>